<template>
  <div class="dashboard">

      <div v-if="isInsegnante || isEducatore">
            <div v-if="getClassi && getClassi.length > 0">
                <h3>Le tue classi</h3>
                <div class="flex">
                    <div class="col col-12 col-sm-6 col-md-4" v-for="classe in getClassi" :key="classe.id">
                      <div class="dashboardBox">
                        <div class="classe">
                        <router-link  class="classeLink" :class="classe.percorso.categoria" :to="{ name: 'Classe' , params: { classeId: classe.id }}">
                            {{ classe.nome }}
                        </router-link>

                          {{ classe.scuola.nome }}<br/>
                          <span v-if="classe.scuola.citta != classe.scuola.comune">{{ classe.scuola.citta }}</span>
                          <em>{{ classe.scuola.comune }}</em><br/>
                          <strong>{{ classe.percorso.titolo }}</strong>

                          <div v-if="classe.insegnanti && !isInsegnante">
                              <small v-for="insegnante in classe.insegnanti" :key="insegnante.id">
                                Docente: {{ insegnante.nome }} 
                              </small>
                          </div>

                          <div v-if="classe.educatori && !isEducatore">
                              <small v-for="educatore in classe.educatori" :key="educatore.id">
                                Tutor: {{ educatore.nome }} 
                              </small>
                          </div>

                          <div v-if="classe.messagginonletti && classe.messagginonletti > 0">
                            <span class="unreaded">
                                {{ classe.messagginonletti }} 
                            </span> Messaggi non letti
                          </div>

                          <div class="password">
                          <strong>Credenziali per gli studenti</strong><br/>
                          Utente: {{ classe.user.username }}<br/>
                          Password: {{ readPass(classe.user.password) }}
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        <div v-else>
          <div class="empty">
           Nessuna classe associata
          </div>
        </div>
      </div>
      <!--
       <div v-if="isEducatore"  class="row">
            <div v-if="getClassi" class="dashboardBox">
                <h3>Le tue classi</h3>
                <div class="classi">
                  <div class="classe" v-for="classe in getClassi" :key="classe.id">
                    <router-link  class="classeLink" :class="classe.percorso.categoria" :to="{ name: 'Classe' , params: { classeId: classe.id }}">
                        {{ classe.nome }}
                    </router-link>
                        {{ classe.scuola.nome }}
                        <span v-if="classe.scuola.citta != classe.scuola.comune">{{ classe.scuola.citta }}</span>
                        {{ classe.scuola.comune }} ({{ classe.scuola.provincia }})
                        <strong>{{ classe.percorso.titolo }}</strong>

                        <div v-if="classe.insegnanti && !isInsegnante">
                            <small v-for="insegnante in classe.insegnanti" :key="insegnante.id">
                              {{ insegnante.nome }} 
                            </small>
                        </div>

                        <div v-if="classe.educatori && !isEducatore">
                            <small v-for="educatore in classe.educatori" :key="educatore.id">
                              {{ educatore.nome }} 
                            </small>
                        </div>

                  </div>
                </div>
              </div>
        <div v-else>
          Nessuna classe associata
        </div>
      </div>
      -->

  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState, mapActions } from 'vuex'
export default {
  name: 'Home',

  data: () => {
    return {
      timer_dashboard: ''
     //classi: []
    }
  },
  
  components: {
    
  },

  computed: {
    ...mapGetters([
      'isLogged',
      'isAdmin',
      'isEducatore',
      'isInsegnante',
      'isClasse',
      'user'
    ]),

    ...mapGetters('classi',[
        'getClassi'
     ])
  },

  methods: {
    ...mapActions('classi',[
        'fetchClassi',
    ]),


    readPass(pass) {
        return atob(pass)
    },

    refreshDashboard() {  
        this.$store.dispatch('classi/fetchClassi')
    }

  },

  beforeCreate() {
    this.$nextTick().then(() => document.body.className = '')
  },

  beforeMount() {
    this.$store.dispatch('classi/fetchClassi')
  },

  mounted() {
    this.timer_dashboard =  window.setInterval(() => {
        this.refreshDashboard()
      }, 20000) 
  },

  destroyed: function() {
    clearInterval(this.timer_dashboard)
  }

}
</script>
